/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! lobsteranywhereSettings ) return;

    var settings = lobsteranywhereSettings,
        ver = lobsteranywhereSettings.assetsVersion;

    // Helper Libraries
    RequireBundle.register( 'animate-helper', [ settings.jsLibPath + 'animate-helper' + ver + '.min.js' ] );
    RequireBundle.register( 'hammerjs', [ settings.jsLibPath + 'hammer' + ver + '.min.js' ] );
    RequireBundle.register( 'polyfill-inert', [ settings.jsLibPath + 'inert' + ver + '.min.js' ] );

    // Components
    RequireBundle.register( 'flyout-block', [ settings.jsLibPath + 'flyout-block' + ver + '.min.js', settings.cssPath + 'flyout-block' + ver + '.min.css' ], '[data-flyout]', function(){ FlyoutBlock.init( settings.flyoutBlock ); } );
    RequireBundle.register( 'collapsible-block', [ settings.jsLibPath + 'collapsible-block' + ver + '.min.js', settings.cssPath + 'collapsible-block' + ver + '.min.css' ], '[data-collapsible],.schema-faq-section', function(){ CollapsibleBlock.init( settings.collapsibleBlock ); } );

    // Theme Style bundles
    RequireBundle.register( 'post-styles', [ settings.cssPath + 'post' + ver + '.min.css' ], '.blog-posts__list,.post-excerpt,.single-post' );
    RequireBundle.register( 'navigation-styles', [ settings.cssPath + 'navigation' + ver + '.min.css' ], '.pagination,.woocommerce-pagination,.nav-links,.page-links' );
    RequireBundle.register( 'comment-styles', [ settings.cssPath + 'comment' + ver + '.min.css' ], '#comments' );
    RequireBundle.register( 'footer-styles', [ settings.cssPath + 'footer' + ver + '.min.css' ], '.site-footer' );
    RequireBundle.register( 'block-yoast-faq', [ settings.cssPath + 'block-yoast-faq' + ver + '.min.css' ], '.schema-faq-section' );
    RequireBundle.register( 'plugin-pikaday-styles', [ settings.cssPath + 'plugin-pikaday' + ver + '.min.css' ], '.pika-single' );

    // Fluid Slider 
    RequireBundle.register( 'fluid-slider', [ settings.jsPath + 'fluid-slider' + ver + '.min.js', settings.cssPath + 'fluid-slider' + ver + '.min.css' ], '.slider-wrapper', function(){ FluidSlider.init( laFluidSliderSettings ); } );

    // Menus
    RequireBundle.register( 'menu-expansible', [ settings.jsPath + 'menu-expansible' + ver + '.min.js' ], '.menu-expansible', function(){ MenuExpansible.init( settings.menuExtensible ); } );
    RequireBundle.register( 'menu-account', [ settings.jsPath + 'menu-account' + ver + '.min.js' ], '.menu-account__menu', function(){ MenuAccount.init( settings.menuAccount ); } );
    RequireBundle.register( 'menu-descriptions', [ settings.jsPath + 'menu-descriptions' + ver + '.min.js' ], '[data-menu-description-container]', function(){ MenuDescriptions.init(); } );

})();
